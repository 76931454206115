@tailwind base;
@tailwind components;
@tailwind utilities;

.wrapper {
  @apply mx-auto w-11/12 max-w-screen-xl clear-both;
}

:root {
  --primary: #705DE4;
  --primary-2: #988ce6;
  --primary-3: #5345ad;
  --secondary: #578AA5;
  --secondary-2: #487188;
  --tertiary: #B2B5B2;
  --tertiary-2: #989b98;
  --text-primary: #1b1b1b;
  --text-secondary: #ffffff;

  --accents-0: #212529;
  --accents-1: #343a40;
  --accents-2: #495057;
  --accents-3: #868e96;
  --accents-4: #adb5bd;
  --accents-5: #ced4da;
  --accents-6: #dee2e6;
  --accents-7: #e9ecef;
  --accents-8: #f1f3f5;
  --accents-9: #f8f9fa;

  --cyan: #22b8cf;
  --green: #37b679;
  --red: #da3c3c;
  --pink: #e64980;
  --purple: #f81ce5;
  --blue: #0070f3;
  --violet-light: #7048e8;
  --violet: #5f3dc4;

  --font-sans: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, 'Helvetica Neue', 'Helvetica', sans-serif;
  --font-serif: "Poppins", -apple-system, system-ui, BlinkMacSystemFont, 'Helvetica Neue', 'Helvetica', sans-serif;
  --header-height: 80px;
  --vh100-offset: calc(100vh - var(--header-height));
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.text-primary {
  color: var(--text-primary);
}

a.text-primary:hover {
  color: var(--primary-2);
}

a.bg-primary:hover {
  background-color: var(--primary-2);
}

html {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  font-feature-settings: 'case' 1, 'rlig' 1, 'calt' 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-family: var(--font-serif);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  color: var(--text-primary);
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-sans);
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.customGradient {
  background-image: linear-gradient(to left bottom, #705de4, #6a58d9, #6554cf, #5f4fc4, #5a4bba, #4b50b7, #3d54b2, #3057ac, #1f60a6, #22679d, #336d93, #487188);
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.height-screen-helper {
  height: var(--vh100-offset);
}

.content-block {
  @apply py-8 bg-white;
}

.content-block h1 {
  @apply mt-2 text-3xl font-extrabold tracking-tight sm:text-5xl;
}

.content-block h2 {
  @apply mt-2 text-2xl font-extrabold tracking-tight sm:text-2xl;
}

.content-block h3, .content-block h4 {
  @apply mt-2 text-xl font-extrabold tracking-tight sm:text-xl;
}

.content-block p {
  @apply mt-3 text-primary text-sm md:text-lg mt-4;
}

.content-block ul {
  @apply pl-5 mb-6
}

.content-block li {
  @apply mt-3 text-primary font-bold text-sm md:text-lg mt-1;
}

.content-block a {
  @apply mt-3 font-bold text-sm md:text-lg mt-4;
}

.hover-3d {
  @apply bg-white p-8 rounded-xl border-4 border-primary shadow-xl;
}

.rangeslider__handle-tooltip {
  width: 90px !important;
  height: 60px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.rangeslider__handle-tooltip span {
  margin: 0 !important;
}

.rangeslider-horizontal .rangeslider__fill {
  background: var(--primary) !important; 
}